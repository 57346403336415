$(function(){
  window.addEventListener("load", function () {
    $('[data-toggle="popover"]').popover({
      html: true,
      container: 'body',
      content: function () {
        return $('#' + $(this).data('content_div_id')).html();
      },
      placement : "bottom"
    });
  });

  var pagetop = $('#scroll_top');
  pagetop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 700) {
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
  pagetop.click(function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
    return false;
  });
});

window.getNotification = function getNotification(){
  setTimeout(function(){loadNotification()}, 15000);
}

function loadNotification(){
  $.getJSON('/notification/json', (data) => {
    if(data.length == 0) {
      setTimeout(function(){loadNotification()}, 15000);
    } else {
      $("#notification_count").text(data.length);
      $("#notification_count").show();
      $('#popover_content').html("<div class='notification_box'><p class='notification_message'><a href='" + data[0]['notification_url'] + "'>" + data[0]['notification_message'] + "</a><span>" + data[0]['created_at'] + "</span></p></div><p class='notification_all_message text-center'><a href='/notification'>全てのお知らせを見る</a></p><p class='notification_all_message text-center'><a href='/user/chckedNotifications'>全て既読に更新</a></p>");
      $("#show_notification_all_message").show();
      $('[data-toggle="popover"]').popover({
        html: true,
        container: 'body',
        content: function () {
          $('#' + $(this).data('content_div_id')).html();
        },
        placement : "bottom",
        trigger: 'focus'
      });
    }
  });
}

window.scrollToBottom = function scrollToBottom() {
  const scroll = document.getElementById('scroll');
  scroll.scrollTo(0, 0xFFFFFFFF);
}
